/** @jsxImportSource @emotion/react */

import { useContext } from 'react';

import { PageContext } from '@stories/templates/Context/pageContext';
import { FORMAT_CURRENCY_OPTIONS } from '@utils/constants';

export type PriceProps = {
  fontSize?: string;
  price: number;
};

export const Price: React.FC<PriceProps> = ({ fontSize = '38px', price }) => {
  const { storeCode } = useContext(PageContext);
  const integerPart = Math.floor(price);
  const decimalPart = Math.round((price - integerPart) * 100);

  return (
    <div css={{ color: 'currentColor', display: 'flex', fontSize, fontWeight: 'bold', lineHeight: '70%' }}>
      <span>{integerPart},</span>
      <span
        css={{
          alignSelf: 'flex-start',
          display: 'block',
          fontSize: '0.5em',
          letterSpacing: '-0.02em',
          lineHeight: '80%',
          marginLeft: '-.2em',
          verticalAlign: 'top'
        }}
      >
        {decimalPart === 0 ? '—' : decimalPart < 10 ? `0${decimalPart}` : decimalPart}
      </span>
      <span
        css={{
          alignSelf: 'flex-end',
          display: 'block',
          fontSize: '0.5em',
          lineHeight: '82%',
          marginLeft: '-.1em'
        }}
      >
        {FORMAT_CURRENCY_OPTIONS[storeCode || 'vomachterhof'].currencySymbol}
      </span>
    </div>
  );
};
