/** @jsxImportSource @emotion/react */

import { useContext } from 'react';

import { formatPrices } from '@common/utlis';
import { Price as PriceAtom } from '@stories/atoms/Price';
import { PageContext } from '@stories/templates/Context/pageContext';

import Badge from '../Badge';

import type { Interpolation, Theme } from '@emotion/react';
import type { TProductES } from '@vitafy/common-schemas';

type PriceProps = TProductES['prices'] & {
  compact?: boolean;
  fixedHeight?: boolean;
  cssProps?: Interpolation<Theme>;
};

const themes = {
  vomachterhof: {
    container: {
      marginTop: '1.5rem'
    }
  }
};

const Price: React.FC<PriceProps> = (props) => {
  const { storeCode } = useContext(PageContext);
  const { base, discount, isSpecialPrice, priceOld, priceValue } = formatPrices(props);
  const { cssProps, fixedHeight = true } = props;

  return (
    <div
      css={{
        position: 'relative',
        marginTop: !fixedHeight && isSpecialPrice ? '3.5rem' : fixedHeight ? '1.25rem' : '.5rem',
        ...themes[storeCode]?.container,
        ...(cssProps as object)
      }}
    >
      {discount !== 0 ? (
        <div
          css={{
            alignItems: 'center',
            display: 'flex',
            position: 'absolute',
            top: 0,
            transform: 'translateY(-100%) translateY(-4px)'
          }}
        >
          <Badge type="discount" label={`- ${discount}%`} />

          <div
            css={(t) => ({
              fontSize: t.font.size.small,
              marginLeft: '4px',
              textDecoration: 'line-through',
              color: t.color.grey.g40
            })}
          >
            {priceOld}
          </div>
        </div>
      ) : (
        ''
      )}
      <span
        css={(t) => ({
          display: 'block',
          ...(isSpecialPrice && { color: t.color.promotion })
        })}
      >
        {/* {/ab/.test(price) ? (
          <>
            <span
              css={(t) => ({
                color: t.color.grey.g72,
                fontWeight: 'normal',
                fontSize: '0.8em'
              })}
            >
              ab
            </span>
            {price.replace(/ab/, '')}
          </>
        ) : ( */}
        <PriceAtom price={priceValue} />
        {/* )} */}
      </span>
      <span
        css={(t) => ({
          color: t.color.grey.g40,
          display: 'block',
          fontSize: t.font.size.tiny,
          fontWeight: 400,
          lineHeight: '1em',
          marginTop: '6px',
          whiteSpace: 'nowrap'
        })}
        dangerouslySetInnerHTML={{ __html: base }}
      />
    </div>
  );
};

export default Price;
export type { PriceProps };
